<template>
    <!-- 退货原因管理 -->
    <div class="container">
      <el-card class="head">
        <div>
          <div class="OperButton">
              <div class="addBtn" @click="add">
                <img src="../../assets/images/add.png" style="margin-right: 5px;width:13px">添加
              </div>
          </div>
        </div>
      </el-card>
      <div style="height: 10px"></div>
      <div class="body">
        <!-- 列表 -->
      <div class="dataTable">
        <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;font-family: PingFangRoutine;" row-key="id" border
                      :header-cell-style="tableHeaderColor" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
              <el-table-column prop="name" label="菜单名称" width="300"></el-table-column>
              <el-table-column prop="code" label="编号" width="100"></el-table-column>
              <el-table-column prop="url" label="路由"></el-table-column>
              <el-table-column prop="icon" label="图标"></el-table-column>
              <el-table-column align="right">
                <template slot="header" slot-scope="scope">操作</template>
                <template slot-scope="scope">
                  <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
                  <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
              </el-table-column>
          </el-table>
        </div>
        <!-- 列表 -->
      </div>
      <div style="height: 10px"></div>
      <div class="footer">
      </div>


      <el-dialog title="新增或修改" :visible.sync="delBool" width="30%" :modal="false">
              <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="上级">
                <Treeselect v-model="form.parentId" :options="options" :normalizer="normalizer" />
              </el-form-item>
              <el-form-item label="编号">
                <el-input v-model="form.code"></el-input>
              </el-form-item>
              <el-form-item label="权限名">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="路由名称">
                <el-input v-model="form.path"></el-input>
              </el-form-item>
              <el-form-item label="组件">
                <el-input v-model="form.component"></el-input>
              </el-form-item>
              <el-form-item label="排序权重">
                <el-input v-model="form.sort"></el-input>
              </el-form-item>
              <el-form-item label="url地址">
                <el-input v-model="form.url"></el-input>
              </el-form-item>
                <el-form-item label="只读?">
                  <el-switch v-model="form.readOnly" active-color="#409EFF" inactive-color="#C0CCDA"></el-switch>
                </el-form-item>
                <el-form-item label="路由缓存">
                  <el-switch v-model="form.keepAlive" active-color="#409EFF" inactive-color="#C0CCDA"></el-switch>
                </el-form-item>
            </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="delBool = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible">确 定</el-button>
              </span>
         </el-dialog>
    </div>

  </template>
  <script>
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { PermissionsAll,permissionAdd,setpermissionUpdate,permissionsDel} from "@/api/permission";
  import "../../font-style/font.css";
  export default {
    components: { Treeselect },
    data() {
      return {
        setting: [],
        options:[],
        value:null,
        op:[],
        parent:[],
        delBool:false,
        selectedNode:[],
        switch:false,
        form: {
         code: '',
         parentId: '',
         name:'',
         icon:'',
         component:'',
         path :'',
         sort:'',
         type:'',
         url:'',
         readOnly:false,
         keepAlive: false,
        },
        tableData: []
      };
    },
    activated() {
      this.list();
    },
    mounted(){
      this.list();
    },
    methods: {
      tableHeaderColor({ rowIndex }) {
        if (rowIndex === 0) {
          return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px';";
        }
      },
      list(){//列表初始化
        PermissionsAll().then((res)=>{
          this.tableData =  res.data;
        })
      },
      handleDelete(v,e){
        this.$alert('确定要删除此权限？', '删除', {
          showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
             beforeClose:(action, instance, done)=>{
            if(action === 'confirm'){
              permissionsDel({id:e.id}).then((data)=>{
                done()
                this.list()
                instance.confirmButtonLoading = false;
              })
            }
          }
          });
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
        }
      },
      //添加
      add(){
       this.form.code=''
        this.form.parentId=null
        this.form.name=''
        this.form.icon=''
        this.form.path=''
        this.form.component=''
        this.form.path =''
        this.form.sort=''
        this.form.type=''
        this.form.url=''
        this.form.keepAlive =false,
        this.delBool=true
        this.switch=true

        const node = {
          id: '0',
          name: '顶级',
          children: this.tableData,
        };

        this.options .push(node);
      },

      //表单确认按钮
      dialogVisible(){
        console.log(this.form.readOnly)
        if(this.switch){
          permissionAdd(this.form).then((data)=>{
          console.log(data)
          if(data.msg=='调用成功'){
            this.$message({
          message: '添加成功',
          type: 'success'
        });
          this.delBool=false
          this.list()
          }
        })
        }else{
          setpermissionUpdate(this.form).then((data)=>{
            if(data.msg=='调用成功'){this.$message({message: '修改成功', type: 'success'});
            this.delBool=false
            this.list()
          }
        })
        }

      },

     handleEdit(v,e){
        this.switch=false
        this.options=[]
        console.log(v,e)
        this.form= Object.assign({}, this.form, e);
        // if(e.parentId == 0 ){
        //  this.form.parentId=e.id
        // }
         this.delBool=true

         const node = {
           id: '0',
           name: '顶级',
           children: this.tableData,
         };

         this.options .push(node);
      },
    },
  };
  </script>
  <style lang="less" scoped>
    @import "../../assets/css/common.less";
  /deep/ .head .el-card__body{
      padding-left: 0px;
    }
    .dataOper{
      padding: 20px;
      padding-left: 0px;
    }
  </style>
